/** @jsx jsx */
import React from 'react';
import { InnerPageLayout } from 'components/InnerPageLayout';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import { fonts } from '../../src/lib/typography';

const BigH1 = styled.h1`
  font-size: 4em;
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;
`;

const Row = styled.div`
  padding-top: 5em;
`;

const Book = styled.img`
  height: 18em;
  box-shadow: -5px 6px 10px rgba(0, 0, 0, 0.35);
`;

const BooksLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const BoldLink = styled.a`
  font-weight: 600;
`;

const Quote = styled.p`
  padding-left: 2em;
  padding-right: 2em;
  font-size: 0.875em;
`

const Attribution = styled.div`
  text-align: right;
`

export default ({ data: { site, allMdx } }) => {
  return (
    <InnerPageLayout site={site}>
      <Row>
        <BooksLayout>
          <Book src="/images/we_the_interwoven_volume_1.jpg" />
          <Book src="/images/we_the_interwoven_volume_2.jpg" />
          <Book src="/images/we_the_interwoven_volume_3.jpg" />
        </BooksLayout>
      </Row>
      <Row>
        <p>
          Alisha is an editor of{' '}
          <i>
            <BoldLink
              href="https://www.biculturalwritersfellowship.com/"
              target="_blank"
            >
              We the Interwoven: An Anthology of Bicultural Iowa
            </BoldLink>
          </i>
          . These stories take us on a ride through the heart and the moral
          conscience as they explore how we find identity and make a future in
          an America that is still deciding its own.
        </p>
        <p>
          <a
            href="https://www.biculturalwritersfellowship.com/"
            target="_blank"
          >
            Volume 1
          </a>{' '}
          brings us the stories of three Americans whose families have found a
          home in the heartland over the past two generations, representing
          their unique experiences and influences from not only Iowa but also
          the coast of Azerbaijan, the border towns of Mexico, and the
          archipelago of the Philippines.
        </p>
        <p>
          <a
            href="https://www.biculturalwritersfellowship.com/"
            target="_blank"
          >
            Volume 2
          </a>{' '}
          introduces seven new voices who explore the theme of living between
          two worlds. These diverse stories speak of the undocumented immigrant
          struggle of a Dreamer, inherited Native trauma in the life of a young
          man coming of age, Bosnian-American cultural traditions, and
          Vietnamese-American filial debt, as well as an Egyptian mother’s gift
          to her daughter, an Armenian grandmother’s influence, and a young
          man’s discovery of hidden Puerto Rican heritage.
        </p>
        <p>
          <a
            href="https://www.biculturalwritersfellowship.com/books-1"
            target="_blank"
          >
            Volume 3
          </a>{' '}
          shares a view of American life through the eyes of seven of its newest citizens.
          Eyad Said and his young family must build a life together in the U.S. 
          while contending with the horrors facing their people back in Syria. 
          Palestinian American Dhuha Tawil grapples with her decision whether 
          or not to wear the hijab. Shalini Jasti tries to balance her love of 
          the English language with her mother’s desire to preserve her connection 
          to their Indian culture and mother tongue. Vanessa “Cueponi Cihuatl” 
          Espinoza tells of her first legal documents after crossing the 
          Mexican–U.S. border as a child. In a moving letter to his parents, 
          George Khal looks back on his life in Palestine, Egypt, and Iowa. 
          Hibbah Jarmakani tells of her family’s struggle to create a new home 
          in Iowa after leaving their generational home behind in Syria. After 
          speaking truth to power as a journalist in Sudan, Salma Salama migrates 
          to Cairo and then the United States, where she must start anew in her 
          career, language, and community.
        </p>
        <Quote>
          "Individually, these are beautiful personal stories of migration and transformation. 
          Together, they are an essential text of contemporary history and geopolitics that 
          everyone should be so lucky to read."
          <Attribution>—Lauren Markham, author of <i>The Far Away Brothers</i></Attribution>
        </Quote>
        <p>
          The Bicultural Iowa Writers’ Fellowship (BIWF) is the state’s first
          fully funded residency for emerging writers who are immigrants and
          non-native Iowans. The fellowship provides education, support, and
          resources from the renowned literary community of Iowa. It offers
          space and time for writers to explore their multilayered identities
          and the platform to share their stories with a statewide audience,
          including publication in the We the Interwoven book series.
        </p>

      </Row>
    </InnerPageLayout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`;
